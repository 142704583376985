<template>
  <v-row class="d-flex flex-column" no-gutters>
    <site-header />
    <resend-confirmation-email flow="free" />
    <site-footer />
  </v-row>
</template>

<script lang="ts">
import Vue from 'vue'

import ResendConfirmationEmail from '~/components/signup/ResendConfirmationEmail.vue'
import SiteFooter from '~/components/SiteFooter.vue'
import SiteHeader from '~/components/SiteHeader.vue'

export default Vue.extend({
  components: {
    SiteFooter,
    SiteHeader,
    ResendConfirmationEmail,
  },
  head() {
    return {
      title: 'Sign Up Email Sent',
    }
  },
})
</script>
