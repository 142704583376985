<template>
  <div :class="['spinner-small', { 'spinner-small--dark': isDark }]">
    <svg viewBox="0 0 30 30">
      <circle class="path" cx="15" cy="15" r="10" fill="none" stroke-width="2" />
    </svg>
  </div>
</template>

<script lang="ts">
export default {
  name: 'SpinnerSmall',
  props: {
    isDark: Boolean,
  },
}
</script>

<style lang="sass">

@keyframes spinner-small-rotate
  0%
    transform: rotate(0deg)
  50%
    transform: rotate(720deg)
  100%
    transform: rotate(1080deg)

@keyframes spinner-small-dash
  0%
    stroke-dashoffset: (0.66 * 20)
  50%
    stroke-dashoffset: (3.14 * 20)
  100%
    stroke-dashoffset: (0.66 * 20)

.spinner-small
  display: none
  width: 30px
  height: 30px
  transform-origin: center
  animation: spinner-small-rotate 4s linear infinite

  .path
    stroke: rgba($c-white, .75)
    stroke-linecap: round
    transform-origin: 10px 10px
    stroke-dasharray: (3.14 * 20)
    animation: spinner-small-dash 4s linear infinite

  &--dark .path
    stroke: $c-grey-dark
</style>
